import {
    Box,
    Button,
    Flex,
    HStack,
    Heading,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { AiOutlineUnlock } from 'react-icons/ai';
import { BsX } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
import { Project } from '../../../data/Project';
import { useAuth } from '../../../hooks/Caffeine';
import { AwaitAPI } from '../../utils/AwaitAPI';
import { RightsEditor } from '../../utils/RightsEditor';
import { SendAPI } from '../../utils/SendAPI';
import { Status } from '../../utils/Status';
import { ArtifactGroupUpload } from '../../utils/Upload/ArtifactGroup';
import { AddReview } from './AddReview';
import { ProjectStatus } from './ProjectStatus';
import { ProjectTags } from './ProjectTags';
import { ReviewList } from './ReviewList';
import { ProjectBasicInfo } from './create/ProjectBasicInfo';

interface IProjectEditProps {}

export function ProjectEdit(props: IProjectEditProps) {
    const { id } = useParams();
    const { user } = useAuth();

    if (!id) {
        return <Status statusCode={404} embeded />;
    }

    return (
        <AwaitAPI request={(api) => api.projects.getById(id)} error={<Status statusCode={404} embeded />}>
            {(project: Project) => (
                <Box m={6} pb={12}>
                    <Flex mb={2}>
                        <Heading fontSize="4xl" fontWeight="semibold" as="h2" lineHeight="tight" mr="auto">
                            {project.getName()}
                        </Heading>
                        <Link to="..">
                            <Button leftIcon={<BsX />}>{t('projectEdit.quit').toString()}</Button>
                        </Link>
                    </Flex>
                    <ProjectTags project={project} />
                    <Tabs>
                        <TabList>
                            <Tab>{t('projectEdit.tabs.status').toString()}</Tab>
                            <Tab>{t('projectEdit.tabs.info').toString()}</Tab>
                            <Tab>{t('projectEdit.tabs.files').toString()}</Tab>
                            <Tab>{t('projectEdit.tabs.rights').toString()}</Tab>
                            {project.userPermissions.includes('review') && (
                                <Tab>
                                    <AiOutlineUnlock />
                                    <Text pl={2}>{t('projectEdit.tabs.admin').toString()}</Text>
                                </Tab>
                            )}
                        </TabList>

                        <TabPanels pt={6}>
                            <TabPanel mt={-4}>
                                <ProjectStatus projectId={id} />

                                <Heading as="h2" fontSize="lg" mb={4} pt={24}>
                                    {t('project.admin.archive').toString()}
                                </Heading>
                                <ReviewList project={project} />
                            </TabPanel>
                            <TabPanel>
                                <ProjectBasicInfo project={project} />
                            </TabPanel>
                            <TabPanel>
                                <Stack spacing={8} direction="column">
                                    {Object.entries(project.blueprint.artifactBlueprints).map(
                                        ([slotName, blueprint], i) => (
                                            <ArtifactGroupUpload
                                                key={i}
                                                project={project}
                                                artifactBlueprint={blueprint!}
                                                slotName={slotName}
                                            />
                                        ),
                                    )}
                                </Stack>
                            </TabPanel>
                            <TabPanel>
                                <AwaitAPI request={(api) => api.entities.perms.getById(project.id)}>
                                    {(perms) => (
                                        <VStack align="stretch">
                                            <SendAPI
                                                value={perms}
                                                request={(api, value) => api.entities.perms.update(value)}
                                                onSubmited={() => {}}
                                                repeatable={true}
                                            >
                                                {(onSubmit, status) => (
                                                    <HStack align="stretch" justifyContent="flex-end">
                                                        <Button
                                                            colorScheme="blue"
                                                            ml={{
                                                                base: '0',
                                                                xl: '4',
                                                            }}
                                                            mt={{
                                                                base: '2',
                                                                xl: '0',
                                                            }}
                                                            onClick={onSubmit}
                                                            isLoading={status === 'sending'}
                                                            isDisabled={status === 'sending'}
                                                        >
                                                            {t('generic.save').toString()}
                                                        </Button>
                                                    </HStack>
                                                )}
                                            </SendAPI>
                                            <RightsEditor
                                                perms={perms}
                                                options={['read', 'write', 'append']}
                                                explanation={{
                                                    read: t('rights.groups.project.read').toString(),
                                                    write: t('rights.groups.project.write').toString(),
                                                }}
                                            />
                                        </VStack>
                                    )}
                                </AwaitAPI>
                            </TabPanel>
                            <TabPanel>
                                <Heading as="h2" fontSize="lg" mb={4}>
                                    {t('project.admin.new').toString()}
                                </Heading>
                                <AddReview project={project} />
                                <Heading as="h2" fontSize="lg" mb={4} mt={8}>
                                    {t('project.admin.archive').toString()}
                                </Heading>
                                <ReviewList project={project} />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
            )}
        </AwaitAPI>
    );
}
