import { IBrandSourceProps } from './IBrandSourceProps';

export function BrandBroken({ svgStyle, outlineStyle, filledStyle }: IBrandSourceProps) {
    return (
        <svg viewBox="0 0 503 474" style={svgStyle}>
            <g transform="matrix(1,0,0,1,-16847,-4177)">
                <g transform="matrix(3.5417,0,0,1.4857,22390.6,28.9254)">
                    <g>
                        <g transform="matrix(-0.275469,-0.147687,0.0619533,-0.656679,-1529.97,6308.83)">
                            <path
                                d="M852.349,4914.08C844.182,4919.12 835.865,4922 826.941,4920.4C795.592,4914.79 815.6,4880.31 799.214,4867.38C780.619,4852.71 703.828,4841.09 703.828,4821.21C703.828,4802.73 744.365,4779.23 772.962,4765.27C798.378,4752.86 755.923,4715.23 783.424,4700.95C803.566,4690.48 892.471,4734.01 926.773,4734.01C991.404,4734.01 1055.4,4647.28 1098.27,4653.78C1123.25,4657.57 1094.54,4722.75 1117.19,4749.51C1140.39,4776.9 1184.39,4769.86 1196.78,4786.41C1207.24,4800.38 1157.6,4800.88 1129.15,4821.12"
                                style={outlineStyle}
                            />
                        </g>
                        <g transform="matrix(0.28235,0,0,0.673082,-1746.48,-190.22)">
                            <path
                                d="M787.857,4526.15C787.857,4526.15 737.289,4511.91 737.289,4502.51C737.289,4484.94 782.877,4470.68 839.028,4470.68C895.18,4470.68 940.768,4484.94 940.768,4502.51C940.768,4504.33 934.741,4582.62 934.741,4582.62L914.65,4625.4L900.618,4670.95L861.6,4616.27L812.646,4582.37L787.857,4526.15Z"
                                style={outlineStyle}
                            />
                        </g>
                        <g transform="matrix(0.28235,0,0,0.673082,-6322,-19.4692)">
                            <path
                                d="M17159.6,4452.06L17194.4,4452.06C17207.4,4452.06 17217.9,4440.97 17217.9,4427.3L17229.9,4310.38C17229.9,4296.71 17216.4,4285.61 17203.4,4285.61L17182.8,4285.61L17184.8,4270.62C17184.8,4231.81 17165,4213.02 17141.4,4200.31C17119.2,4188.3 17067,4183.31 17043.6,4183.31C17020.3,4183.31 16972.7,4188.41 16950.8,4200.31C16927.5,4213.02 16903.5,4231.81 16903.5,4270.62L16923.5,4425.51C16923.5,4464.32 16955,4495.82 16993.8,4495.82L17094.4,4495.82C17123.9,4495.82 17149.1,4477.72 17159.6,4452.06ZM17179.4,4311.99L17164.8,4425.51L17164.8,4425.68L17176.3,4425.68C17186.1,4425.68 17191,4417.34 17191,4407.06L17200,4330.62C17200,4320.34 17195.1,4311.99 17185.3,4311.99L17179.4,4311.99ZM16993,4272.47C16993,4272.47 16942.4,4258.23 16942.4,4248.82C16942.4,4231.26 16988,4217 17044.1,4217C17100.3,4217 17145.9,4231.26 17145.9,4248.82C17145.9,4250.65 17139.8,4328.94 17139.8,4328.94L17119.8,4371.72L17105.7,4417.27L17066.7,4362.59L17017.8,4328.68L16993,4272.47Z"
                                style={filledStyle}
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
