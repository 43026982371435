import { IBrandSourceProps } from './IBrandSourceProps';

export function BrandDefault({ svgStyle, outlineStyle, filledStyle }: IBrandSourceProps) {
    return (
        <svg viewBox="0 0 340 371" style={svgStyle}>
            <g transform="matrix(1,0,0,1,-16929,-2418)">
                <g transform="matrix(3.5417,0,0,1.4857,22390.6,28.9254)">
                    <g transform="matrix(1,0,0,1,389.801,-1.22213e-12)">
                        <g>
                            <g transform="matrix(0.28235,0,0,0.673082,-6322,1233.61)">
                                <path
                                    d="M15775.6,654.073C15789.1,659.474 15797.2,666.27 15797.2,673.65C15797.2,691.216 15751.6,705.476 15695.4,705.476C15639.3,705.476 15593.7,691.216 15593.7,673.65C15593.7,666.269 15601.7,659.471 15615.2,654.07L15615.2,619.646C15610.3,621.283 15605.8,623.109 15602.1,625.131C15578.8,637.85 15554.8,656.637 15554.8,695.444L15574.8,850.336C15574.8,889.142 15606.3,920.648 15645.1,920.648L15745.7,920.648C15775.2,920.648 15800.4,902.546 15810.9,876.888L15845.7,876.888C15858.7,876.888 15869.2,865.79 15869.2,852.121L15881.2,735.202C15881.2,721.533 15867.7,710.436 15854.7,710.436L15834.1,710.436L15836.1,695.444C15836.1,656.637 15816.3,637.85 15792.7,625.131C15788.2,622.647 15782.3,620.464 15775.6,618.562L15775.6,654.073ZM15830.7,736.815L15816.1,850.336L15816.1,850.508L15827.6,850.508C15837.4,850.508 15842.3,842.162 15842.3,831.881L15851.3,755.443C15851.3,745.162 15846.4,736.815 15836.6,736.815L15830.7,736.815Z"
                                    style={filledStyle}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,-66.9643,467.381)">
                                <g transform="matrix(0.28235,0,0,0.673082,-6322,-19.4692)">
                                    <path
                                        d="M16004.5,1735.08C16010.1,1735.2 16012.7,1740.15 16012.8,1742.7C16012.9,1760.22 16012.8,1840.97 16012.8,1840.97L15979.2,1840.94L15886.1,1840.94L15852.5,1840.97C15852.5,1840.97 15852.4,1760.22 15852.5,1742.7C15852.5,1740.12 15855.2,1735.07 15860.9,1735.08C15861.7,1735.08 15862.8,1735.08 15864.1,1735.08L15864.1,1735.03L16004.5,1735.03L16004.5,1735.08Z"
                                        style={outlineStyle}
                                    />
                                </g>
                                <g transform="matrix(0.28235,0,0,0.673082,-6322,-19.4692)">
                                    <path d="M15979.2,1735.03L15979.2,1840.94" style={outlineStyle} />
                                </g>
                                <g transform="matrix(0.28235,0,0,0.673082,-6322,-19.4692)">
                                    <path
                                        d="M15886.1,1840.94C15886.1,1805.63 15886.1,1770.33 15886.1,1735.03C15886.1,1770.33 15886.1,1805.63 15886.1,1840.94Z"
                                        style={outlineStyle}
                                    />
                                </g>
                                <g transform="matrix(0.199147,-0.0363527,0.0215564,0.671074,-4895.89,-183.994)">
                                    <path d="M15008.7,2824.81L15056.1,2827.39" style={outlineStyle} />
                                </g>
                                <g transform="matrix(0.199139,-0.0365966,0.021701,0.671048,-4896.18,-162.023)">
                                    <path d="M15008.7,2824.81L15056.1,2827.39" style={outlineStyle} />
                                </g>
                                <g transform="matrix(0.199147,-0.0363527,0.0215564,0.671074,-4895.89,-147.533)">
                                    <path d="M15008.7,2824.81L15056.1,2827.39" style={outlineStyle} />
                                </g>
                                <g transform="matrix(0.199147,-0.0363527,0.0215564,0.671074,-4860.11,-183.994)">
                                    <path d="M15008.7,2824.81L15056.1,2827.39" style={outlineStyle} />
                                </g>
                                <g transform="matrix(0.199139,-0.0365966,0.021701,0.671048,-4860.4,-162.023)">
                                    <path d="M15008.7,2824.81L15056.1,2827.39" style={outlineStyle} />
                                </g>
                                <g transform="matrix(0.199147,-0.0363527,0.0215564,0.671074,-4860.1,-147.533)">
                                    <path d="M15008.7,2824.81L15056.1,2827.39" style={outlineStyle} />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
