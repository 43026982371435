import { IBrandSourceProps } from './IBrandSourceProps';

export function BrandSimple({ svgStyle, outlineStyle, filledStyle }: IBrandSourceProps) {
    return (
        <svg viewBox="0 0 340 366" style={svgStyle}>
            <g transform="matrix(1,0,0,1,-16929,-561)">
                <g transform="matrix(3.5417,0,0,1.4857,22390.6,28.9254)">
                    <g transform="matrix(0.28235,0,0,0.673082,-5932.19,-19.4692)">
                        <path
                            d="M15775.6,654.073C15789.1,659.474 15797.2,666.27 15797.2,673.65C15797.2,691.216 15751.6,705.476 15695.4,705.476C15639.3,705.476 15593.7,691.216 15593.7,673.65C15593.7,666.269 15601.7,659.471 15615.2,654.07L15615.2,619.646C15610.3,621.283 15605.8,623.109 15602.1,625.131C15578.8,637.85 15554.8,656.637 15554.8,695.444L15574.8,850.336C15574.8,889.142 15606.3,920.648 15645.1,920.648L15745.7,920.648C15775.2,920.648 15800.4,902.546 15810.9,876.888L15845.7,876.888C15858.7,876.888 15869.2,865.79 15869.2,852.121L15881.2,735.202C15881.2,721.533 15867.7,710.436 15854.7,710.436L15834.1,710.436L15836.1,695.444C15836.1,656.637 15816.3,637.85 15792.7,625.131C15788.2,622.647 15782.3,620.464 15775.6,618.562L15775.6,654.073ZM15830.7,736.815L15816.1,850.336L15816.1,850.508L15827.6,850.508C15837.4,850.508 15842.3,842.162 15842.3,831.881L15851.3,755.443C15851.3,745.162 15846.4,736.815 15836.6,736.815L15830.7,736.815Z"
                            style={filledStyle}
                        />
                    </g>
                    <g transform="matrix(0.28235,0,0,0.673082,-4038.43,-1020.91)">
                        <path
                            d="M9060.18,2055.61C9065.79,2055.73 9068.42,2060.68 9068.44,2063.22C9068.54,2080.75 9068.44,2161.5 9068.44,2161.5L9034.84,2161.5L9034.84,2161.46L8941.74,2161.46L8941.74,2161.5L8908.15,2161.5C8908.15,2161.5 8908.04,2080.75 8908.15,2063.22C8908.17,2060.65 8910.87,2055.6 8916.63,2055.61C8917.39,2055.61 8918.47,2055.61 8919.76,2055.61L8919.76,2055.55L9060.18,2055.55L9060.18,2055.61Z"
                            style={outlineStyle}
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}
